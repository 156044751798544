import React, { useEffect } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

import Header from './Template/Header'

export default function AboutUs() {

    useEffect(() => {
        window.scrollTo(0,0);
    });

    return(
        <div>
            <Header title="About Us"/>
            <Container>
                <Row>
                    <Col lg={6}>
                        <Image src="https://firebasestorage.googleapis.com/v0/b/bludentistry-d1204.appspot.com/o/Images%2Fph4.jpg?alt=media&token=6c526e8d-b51b-4fe7-937b-a006ecf7735a" className="img-fluid"></Image>
                    </Col>
                    <Col lg={6}>
                        <Row>
                            <Col>
                                <h2>Our Philosophy</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>
                                    Dr. Nguyen received his DDS degree from the University of Southern California in 1999. Underneath his
                                    humble and approachable personality lies his strong desire to become the absolute best he could ever
                                    be. Since then, he has continuously worked on broadening his knowledge and to further strengthen his
                                    expertise only to achieve one goal - to provide quality dental work to each and every one of his
                                    patients. His list of post-graduate training includes Comprehensive Esthetic Residency program at
                                    Esthetic Professionals and Comprehensive Surgical and Restorative Implant Training program at USC
                                    School of Dentistry.
                                </p>
                                <p>
                                    Since stepping into the field of dentistry, Dr. Nguyen has not looked back. He will continue to
                                    polish his skills and knowledge to provide the best dental solutions to his patients.
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>

    )
}