import React from 'react';
import {Container, Row, Col, Button} from 'react-bootstrap';
import ServiceCard from './Services/ServiceCard'
import {LinkContainer} from "react-router-bootstrap";

export default function ResourceCards() {
    return(
        <div fluid className="services">
            <Container>
                <Row>
                    <Col>
                        <h2>We care about your health.</h2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="text">Our team prides in our ability to provide great quality care for every patient. We strive to provide a relaxed environment for you to receive treatment unique to your needs.</p>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col xs={12} md={4} xl={3}>
                        <ServiceCard imageurl={"https://firebasestorage.googleapis.com/v0/b/bludentistry-d1204.appspot.com/o/Images%2FThumbnails%2Fasset-1.png?alt=media&token=1d938bff-798c-4ff8-a5d7-74fcd2633066"}
                                     title={"General Dentistry"}
                                     text={"Our friendly dentists will work with you to find the best plan to get the great smile that you deserve."}
                        />
                    </Col>
                    <Col xs={12} md={4} xl={3}>
                        <ServiceCard imageurl={"https://firebasestorage.googleapis.com/v0/b/bludentistry-d1204.appspot.com/o/Images%2FThumbnails%2Fasset-2.png?alt=media&token=e1958a0e-bd18-4b1a-b60d-46189003942e"}
                                     title={"Emergency"}
                                     text={"We will make it our priority to treat you as soon as possible at the event of an emergency."}
                        />
                    </Col>
                    <Col xs={12} md={4} xl={3}>
                        <ServiceCard imageurl={"https://firebasestorage.googleapis.com/v0/b/bludentistry-d1204.appspot.com/o/Images%2FThumbnails%2Fasset-4.png?alt=media&token=a0d34c59-5894-48a8-986a-d6ec30ddab1c"}
                                     title={"Dental Hygiene"}
                                     text={"Consistent dental check ups will guarantee a healthy, white smile for years to come."}
                        />
                    </Col>
                    <Col xs={12} md={4} xl={3}>
                        <ServiceCard imageurl={"https://firebasestorage.googleapis.com/v0/b/bludentistry-d1204.appspot.com/o/Images%2FThumbnails%2Fasset-3.png?alt=media&token=c0c2d900-abf3-4ab5-aeb7-7a5e864efe6e"}
                                     title={"Teeth Whitening"}
                                     text={"We will provide you with whiter teeth using our state of the art whitening technology."}
                        />
                    </Col>
                </Row>
                <Row className="text-center">
                    <Col>
                        {/*// TODO: create services page*/}
                        {/*<LinkContainer to="/contactus">*/}
                        {/*    <Button className="service-button"> View All Services</Button>*/}
                        {/*</LinkContainer>*/}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}