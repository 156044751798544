import React, { useEffect } from 'react'

import HeroSection from './Home/HeroSection'

import Services from './Home/Services'
import Biography from './Home/Biography'
import Location from './Home/Location'

export default function Home() {

    useEffect(() => {
        window.scrollTo(0,0);
    });

    return(
        <div>
            <HeroSection />
            <Services />
            <Biography />
            <Location />
        </div>
    )
}