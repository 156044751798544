import React  from 'react'
import {Container, Row, Col, Button} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";


export default function Location() {

    return(
        <div>
            <Container className="location">
                <Row>
                    <Col xs={12} md={5}>
                        <LinkContainer to='/contactus'>
                            <a>
                                <img className="map" src="https://firebasestorage.googleapis.com/v0/b/bludentistry-d1204.appspot.com/o/Images%2FThumbnails%2Fmap.png?alt=media&token=4c70e6a1-d9be-4e5c-a95d-9625a78792ce" />
                            </a>
                        </LinkContainer>
                    </Col>
                    <Col>
                        <h2 className="title" ><b>Contact Us</b></h2>
                        <p className="description">
                            Whether you are a new or returning patient, we would be more than happy to assist you. Let us know how we can help by reaching us through phone or email!
                        </p>
                        <Row className="mb-4">

                            <Col xs={3}>
                                <img className="icon" src="https://firebasestorage.googleapis.com/v0/b/bludentistry-d1204.appspot.com/o/Images%2FIcons%2Femergency.svg?alt=media&token=edd2f3b8-58f2-4a1f-941a-1be73c32e772" />
                            </Col>

                            <Col xs={9}>
                                <a href="tel:818-993-1369">
                                    <h3 className="mb-0"><b>Our Phone</b></h3>
                                    <p className="mt-0">818-993-1369</p>
                                </a>

                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col xs={3}>
                                <img className="icon" src="https://firebasestorage.googleapis.com/v0/b/bludentistry-d1204.appspot.com/o/Images%2FIcons%2Fbuilding.svg?alt=media&token=17bdac23-fe60-4896-be79-4fa367c6f0b4" />
                            </Col>
                            <Col xs={9}>
                                <a href='http://maps.google.com/?q=8962 Corbin Ave, Northridge CA 91324, United States'>
                                    <h3 className="mb-0"><b>Address</b></h3>
                                    <p className="mb-0">8962 Corbin Ave, Northridge</p>
                                    <p className="mt-0">CA 91324, United States</p>
                                </a>
                            </Col>
                        </Row>
                        <Row className="mb-4">

                            <Col xs={3}>
                                <img className="icon" src="https://firebasestorage.googleapis.com/v0/b/bludentistry-d1204.appspot.com/o/Images%2FIcons%2Fmedical-laptop.svg?alt=media&token=32cf8270-715e-4fe1-bf1f-d82664502493" />
                            </Col>
                            <Col xs={9}>
                                <a href="mailto:bludentistry@gmail.com">
                                    <h3 className="mb-0"><b>Write Us</b></h3>
                                    <p className="mt-0">BluDentistry@gmail.com</p>
                                </a>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}