import React, { useEffect } from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap';

import Header from './Template/Header'

export default function Resources() {

    useEffect(() => {
        window.scrollTo(0,0);
    });

    return(
        <div>
            <Header title="Resources"/>
            <Container>
                <Row>
                    <Col lg={6}>
                        <Image src="https://firebasestorage.googleapis.com/v0/b/bludentistry-d1204.appspot.com/o/Images%2Fph3.jpg?alt=media&token=f8bd2e66-e8ea-40e6-b90f-d5086378bfec" className="img-fluid"></Image>
                    </Col>
                    <Col lg={6}>
                        <Row>
                            <Col>
                                <p>
                                    While visiting your dentist on a regular basis, preferably every 6 months, is essential in maintaining
                                    your healthy white teeth, that alone is not sufficient. The reality is that your daily oral care carries
                                    as much importance as your dental check-ups. We strongly recommend our patient to follow the regimen
                                    below in order to prolong dental health:
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>
                                    <ul>
                                        <li>
                                            <b>Brushing</b> - Brush your teeth twice a day. Make sure each brushing session is 2 to 3 minutes long
                                            covering the entire mouth. Patients often neglect covering harder-to-reach areas (i.e. back
                                            teeth) but it is important that you brush them completely and thoroughly.
                                        </li>
                                        <li>
                                            <b>Flossing</b> - You should floss your teeth on a daily basis as brushing alone is not sufficient in
                                            eliminating plaque and food particles hidden between your teeth and underneath the gumline. Many
                                            patients floss once a day. While this is acceptable. it is best if you can floss after each
                                            meal.
                                        </li>
                                        <li>
                                            <b>Balanced Diet</b> - Remember, a proper oral care starts from eating more balanced diet, just like
                                            you would if you want to maintain a healthy body. Consumption of fruits and vegetables will
                                            provide the body Â as well as the teeth and gums with proper vitamins and minerals. Sweets are
                                            OK but make sure to eat them in moderation as they are harsh on your teeth. If you cannot resist
                                            the sweet temptation, try using sugar substitutes such as Splenda.
                                        </li>
                                        <li>
                                            <b>Aim for a Healthy Lifestyle</b> - Another aspect that is often forgotten but is equally important in
                                            maintaining your dental health is leading a healthy lifetyle. A regular exercise regimen will
                                            lead to a healthier body and healthier body will lead to healthier teeth and gums. Reduction in
                                            stress is also necessary for proper oral care.
                                        </li>
                                    </ul>
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}