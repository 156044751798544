import React from 'react';
import { Row, Col } from 'react-bootstrap';

export default function HoursRow(props) {
    if (props.closed) {
        return (
            <Row className="hours-row">
                <Col>
                    <p>{props.day}:</p>
                </Col>
                <Col>
                    <p>Closed</p>
                </Col>
            </Row>
        )
    } else {
        return (
            <Row className="hours-row">
                <Col>
                    <p>{props.day}:</p>
                </Col>
                <Col>
                    <p>{props.start} - {props.end}</p>
                </Col>
            </Row>
        )
    }
}