import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import HoursRow from "./Footer/HoursRow";
import LinkRow from "./Footer/LinkRow";

export default function Footer() {
    return(

        <footer className="footer">
            <h2 className="title center">Blu Dental</h2>
            <Container>
                <Row>
                    <Col xs={12} className="content">
                        <p>
                            At Blu Dental, our friendly team of dentists, hygenists, and receptionists
                            will help you get your dental needs met and provide you with
                            an unrivaled patient experience.
                        </p>
                        <h3>Phone</h3>
                        <a href="tel:818-993-1369"><i className="fas fa-phone-alt pr-1"/>818-993-1369</a>
                    </Col>
                    <Col xs={12} md={3} className="content">
                        <h3>About Us</h3>
                        <LinkRow title={"About Us"} link={"/aboutus"}/>
                        <LinkRow title={"Resources"} link={"/resources"}/>
                        <LinkRow title={"Contact Us"} link={"/contactus"}/>
                        {/*<LinkRow title={"Our Dental Team"} link={"/"}/>*/}
                        {/*<LinkRow title={"Our Awards"} link={"/"}/>*/}
                        {/*<LinkRow title={"Dental Services"} link={"/"}/>*/}
                        {/*<LinkRow title={"Pricing & Insurance"} link={"/"}/>*/}
                        {/*<LinkRow title={"Our Solutions"} link={"/"}/>*/}
                        {/*<LinkRow title={"Clients & Testimonials"} link={"/"}/>*/}
                    </Col>
                    <Col xs={12} md={4} className="content">
                        <h3>Location</h3>
                        <p style={{marginBottom: "0"}}>8962 Corbin Ave</p>
                        <p>Northridge, CA, 91324</p>
                    </Col>
                    <Col xs={12} md={5} className="content">
                        <h3>Working Hours</h3>
                        <HoursRow day={"Monday"} start={"10am"} end={"5pm"}/>
                        <HoursRow day={"Tuesday"} start={"9am"} end={"12pm"}/>
                        <HoursRow day={"Wednesday"} start={"9am"} end={"5pm"}/>
                        <HoursRow day={"Thursday"} start={"9am"} end={"5pm"}/>
                        <HoursRow day={"Friday"} start={"9am"} end={"12pm"}/>
                        <HoursRow day={"Saturday"} start={"9am"} end={"3pm"}/>
                        <HoursRow day={"Sunday"} closed={true}/>
                    </Col>
                </Row>
            </Container>
            <img alt="" className="top-mask" src="https://firebasestorage.googleapis.com/v0/b/bludentistry-d1204.appspot.com/o/Images%2FMasks%2Fmasktop-5.png?alt=media&token=16b4d047-f35f-42f2-b763-60d476b7efa3https://firebasestorage.googleapis.com/v0/b/bludentistry-d1204.appspot.com/o/Images%2FMasks%2Fmasktop-5.png?alt=media&token=16b4d047-f35f-42f2-b763-60d476b7efa3" />
        </footer>
    )
}