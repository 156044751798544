import React, { useEffect } from 'react'

import { Card, Container, Row, Col } from 'react-bootstrap'

import Header from './Template/Header'
import ContactForm from './ContactUs/SendEmail'

export default function ContactUs() {

    useEffect(() => {
        window.scrollTo(0,0);
    });

    return(
        <div>
            <Row className="contactus-spacer">

            </Row>
            <Row>
                <Col>
                    <iframe width="100%" height="600" frameborder="0" style={{border:0}} title="Map"
                        src="https://www.google.com/maps/embed/v1/place?q=place_id:Eio4OTU4IENvcmJpbiBBdmUsIE5vcnRocmlkZ2UsIENBIDkxMzI0LCBVU0EiMRIvChQKEgnpFw8AdpvCgBFxri3p5v-YkBD-RSoUChIJ_XoVqO2bwoARu3lbLsKiikE&key=AIzaSyATX8yJa5kvKgSJDyIjwpYkk50wbnvTY0I"
                        allowfullscreen>
                    </iframe>
                </Col>
            </Row>
            <Container>
                <Row className="center" style={{paddingBottom: '130px'}}>
                    <Col className="center" style={{position: 'absolute', transform: 'translate(0px, -100px)'}}>
                        <Card className="shadow text-center" style={{ width: '18rem'}}>
                            <Card.Body>
                                <Card.Title><h2>Address</h2></Card.Title>
                                <Card.Text>
                                    <h5>
                                        Blü Dentistry <br />
                                        8962 Corbin Ave <br />
                                        Northridge<br />
                                        CA, 91324 <br />
                                    </h5>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {/*<Container>*/}
            {/*    <Row className="center">*/}
            {/*        <ContactForm />*/}
            {/*    </Row>*/}
            {/*</Container>*/}
        </div>
        
    )
}