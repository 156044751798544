import React from 'react';
import './sass/main.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Navbar from './components/Navbar';
import Home from './components/Home';
import Footer from './components/Footer';
import AboutUs from './components/AboutUs';
import Services from './components/Services';
import Resources from './components/Resources';
import ContactUs from './components/ContactUs';
import InfoBar from './components/InfoBar';


function App() {
  return (
    <div className="app-root">
      <Router>
        <InfoBar />
        <Navbar />
        <Switch>
          <Route path="/" exact component={Home}/>
          <Route path="/aboutus" component={AboutUs}/>
          <Route path="/services" component={Services}/>
          <Route path="/resources" component={Resources}/>
          <Route path="/contactus" component={ContactUs}/>
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
