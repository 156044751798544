import React from 'react'
import { Jumbotron, Container, Row, Col } from 'react-bootstrap';

export default function Header(props) {
    return(
        <Jumbotron className="page-header">
            <Container>
                <Row>
                    <Col>
                        <h1>{props.title}</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
    )
}