import React from 'react';
import { Card } from 'react-bootstrap';

export default function RecipeCard(props) {
    return (
        <Card className="service-card" style={{backgroundColor: "transparent", border: "none"}}>
            <Card.Body className ="pt-0">
                <Card.Img className="card-img" variant="top" src={props.imageurl} />
                <Card.Title className="card-title">{props.title}</Card.Title>
                <Card.Text className="card-text">{props.text}</Card.Text>
                {/*TODO: Create services page*/}
                {/*<Card.Link href="#" className="card-link">Read more ></Card.Link>*/}
            </Card.Body>
        </Card>
    )
}