import React from 'react'
import { Jumbotron, Container, Row, Col, Button } from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';

import '../Home.css'

export default function HeroSection() {
    return (
        <div>
            <Jumbotron className="hero" >
                <Container>
                    <Row>
                        <Col md={6}>
                            <p className="subtitle">Time to</p>
                            <p className="title">Smile!</p>
                            <h1>Our extraordinary team will get to the root of your problems!</h1>
                            <p>
                                <Button href="tel:818-993-1369" variant="primary"> 818-993-1369</Button>
                            </p>
                            <p>
                                <LinkContainer to="/contactus">
                                    <Button variant="primary"> Our Location</Button>
                                </LinkContainer>
                            </p>
                        </Col>
                        <Col style={{position: "static"}}>
                            <img alt="" className="family" src="https://firebasestorage.googleapis.com/v0/b/bludentistry-d1204.appspot.com/o/Images%2FBillboard%2Fslide3.png?alt=media&token=4d5ccb65-b600-4524-844b-b3f2156d23a5" />
                        </Col>
                    </Row>
                </Container>
                <img alt="" className="bottom-mask" src="https://firebasestorage.googleapis.com/v0/b/bludentistry-d1204.appspot.com/o/Images%2FMasks%2Fmaskbot-1.png?alt=media&token=e67b0813-178f-48db-8706-e2cb26d81999" />

            </Jumbotron>


        </div>
    )
}