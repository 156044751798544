import React, { useEffect } from 'react'
import {Container, Jumbotron, Row, Col} from "react-bootstrap";


export default function Home() {

    useEffect(() => {
        window.scrollTo(0,0);
    });

    return(
        <div>
            <Jumbotron className="biography">
                <Container>
                    <Row>
                        <Col xs={12} md={6}>
                            <p className="subtitle">Welcome to</p>
                            <p className="title"><b>Blu Dental</b></p>
                            <hr />
                            <p className="text">
                                We at Blu Dental are focused on helping you.
                                Our small team of experts will ensure you will
                                get the great dentistry care you deserve.
                            </p>
                            <hr />
                            {/*<h2 className="name">Minh Nguyen</h2>*/}
                            {/*<h4 className="position">Dentist</h4>*/}
                        </Col>
                        {/*<Col>*/}
                            {/*// TODO: Make dots go behind dentist*/}
                            {/*<img className="small-dot" src="https://firebasestorage.googleapis.com/v0/b/bludentistry-d1204.appspot.com/o/Images%2FIllustrations%2FSmallCircleTransparentWhite.png?alt=media&token=0ade9a4b-88c7-48b0-9071-35bbe1ed2e54" />*/}
                            {/*<img className="big-dot" src="https://firebasestorage.googleapis.com/v0/b/bludentistry-d1204.appspot.com/o/Images%2FIllustrations%2FLargeCircleTransparentWhite.png?alt=media&token=0d9e447c-7f00-4ef1-8f2e-d31b617b11ce" />*/}
                            {/*<img className="dentist" src="https://2s2wam2ptwer35jadb291i6d-wpengine.netdna-ssl.com/wp-content/uploads/2017/09/dentist-PNG-768x1036.png" />*/}
                        {/*</Col>*/}
                    </Row>
                    {/*<img className="mobile-dentist" src="https://2s2wam2ptwer35jadb291i6d-wpengine.netdna-ssl.com/wp-content/uploads/2017/09/dentist-PNG-768x1036.png" />*/}

                </Container>
                <img alt="" className="top-mask" src="https://firebasestorage.googleapis.com/v0/b/bludentistry-d1204.appspot.com/o/Images%2FMasks%2Fmasktop-2.png?alt=media&token=69025bad-4350-490b-babb-b8c1ee7445ea" />
                <img alt="" className="bottom-mask" src="https://firebasestorage.googleapis.com/v0/b/bludentistry-d1204.appspot.com/o/Images%2FMasks%2Fmaskbot-1.png?alt=media&token=e67b0813-178f-48db-8706-e2cb26d81999" />
            </Jumbotron>
        </div>
    )
}