import React, { useEffect } from 'react'

import Header from './Template/Header'
import { Container, Row, Col, Card } from 'react-bootstrap';

export default function Services() {

    useEffect(() => {
        window.scrollTo(0,0);
    });

    return(
        <div>
            <Header title="Dental Health Services"/>
            <Container fluid>
                <Row>
                    <Col md={4} className="padding">
                        <Card>
                            <i class="fas fa-user-md" />
                            <Card.Body>
                                <Card.Title>
                                    Routine Dental Care
                                </Card.Title>
                                <Card.Text>
                                    <ul class="text-left">
                                        <li>Dental Cleaning and Examinations</li>
                                        <li>Emergency Dental Care</li>
                                        <li>Root Canal Treatment</li>
                                        <li>Tooth Extraction</li>
                                    </ul>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} className="padding">
                        <Card>
                            <i class="fas fa-notes-medical" />
                            <Card.Body>
                                <Card.Title>
                                    Cosmetic Dental Services
                                </Card.Title>
                                <Card.Text>
                                    <ul class="text-left">
                                        <li>Composite Fillings</li>
                                        <li>Dental Bonding</li>
                                        <li>Dental Crowns</li>
                                        <li>Dental Veneers</li>
                                        <li>Smile Makeover</li>
                                    </ul>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} className="padding">
                        <Card>
                            <i class="fas fa-tooth" />
                            <Card.Body>
                                <Card.Title>
                                    Denture Services
                                </Card.Title>
                                <Card.Text>
                                    <ul class="text-left">
                                        <li>Dentures and Partial Dentures</li>
                                    </ul>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} className="padding">
                        <Card>
                            <i class="fas fa-clinic-medical" />
                            <Card.Body>
                                <Card.Title>
                                    Zoom Teeth Whitening Services
                                </Card.Title>
                                <Card.Text>
                                    <ul class="text-left">
                                        <li>Professional Teeth Whitening</li>
                                    </ul>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} className="padding">
                        <Card>
                            <i class="fas fa-teeth" />
                            <Card.Body>
                                <Card.Title>
                                    Help! Dental Emergency!
                                </Card.Title>
                                <Card.Text>
                                    <ul class="text-left">
                                        <li>Emergency Dentist</li>
                                    </ul>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}