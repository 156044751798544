import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';

export default function MyNav() {
    return(
        <Navbar expand="lg" className="infobar pb-0 pt-0">
            {/*<Navbar.Toggle aria-controls="basic-navbar-nav" />*/}
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                    <Navbar.Text className="pr-1"><i className="far fa-clock" /></Navbar.Text>
                    <Navbar.Text className="pr-3"><b>Mon</b> 10AM-5PM</Navbar.Text>
                    <Navbar.Text className="pr-3"><b>Tue</b> 9AM-12PM</Navbar.Text>
                    <Navbar.Text className="pr-3"><b>Wed</b>-<b>Thu</b> 9AM-12PM</Navbar.Text>
                    <Navbar.Text className="pr-3"><b>Fri</b> 9AM-12PM</Navbar.Text>
                    <Navbar.Text className="pr-3"><b>Sat</b> 9AM-3PM</Navbar.Text>
                    <Navbar.Text className="pr-5"><b>Sun</b> CLOSED</Navbar.Text>
                    <Navbar.Text className="pr-1"><i className="fas fa-map-marker-alt" /></Navbar.Text>
                    <Navbar.Text><b>Address</b> 8962 Corbin Ave, Northridge, CA</Navbar.Text>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}