import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap'

export default function MyNav() {

    const [scrollState, setScrollState] = useState("top");
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        let listener = document.addEventListener("scroll", e => {
            var scrolled = document.scrollingElement.scrollTop
            if (scrolled >= 120) {
                if(scrollState !== "#8FC1E3") {
                    setScrollState("#8FC1E3")
                }
            } else {
                if(scrollState !== "rgba(0, 0, 1, 0)") {
                    setScrollState("rgba(0, 0, 1, 0)")
                }
            }
        })
        return () => {
            document.removeEventListener("scroll", listener)
        }
    }, [scrollState])

    return(
        <Navbar collapseOnSelect onToggle={(e) => {if(e) setScrollState("#8FC1E3")}} sticky="top" variant="light" expand="lg" className="bottom-nav pb-0" style={{backgroundColor: scrollState}}>
            <LinkContainer to="/" exact>
                <Navbar.Brand>
                    <img className="brand" src={"https://firebasestorage.googleapis.com/v0/b/bludentistry-d1204.appspot.com/o/Images%2FLogos%2FBlue%20Tooth%20Icon%20Dental%20Logo-3.png?alt=media&token=8d3701d5-cf33-46e9-a315-159c298bfe5b"} alt="Hero" />
                </Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                    <LinkContainer to="/" exact>
                        <Nav.Link className="pr-5">Home</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/aboutus">
                        <Nav.Link className="pr-5">About Us</Nav.Link>
                    </LinkContainer>
                    {/* <LinkContainer to="/services">
                        <Nav.Link>Services</Nav.Link>
                    </LinkContainer> */}
                    <LinkContainer to="/resources">
                        <Nav.Link className="pr-5">Resources</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/contactus">
                        {/*<Nav.Link>Contact Us</Nav.Link>*/}
                        <Nav.Link>Locate us</Nav.Link>
                    </LinkContainer>
                    <Button className="mb-3 nav-button" variant="secondary" href="tel:818-993-1369"><i className="fas fa-phone-alt pr-1"/>Call Us</Button>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}