import React from 'react';
import { Row, Col } from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';


export default function LinkRow(props) {
    return (
        <Row className="link-row">
            <Col>
                <LinkContainer to={props.link}>
                    <a>{props.title}</a>
                </LinkContainer>
            </Col>
        </Row>
    )
}